import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import { withStyles } from '@material-ui/core/styles';

import IconImageEye from '@material-ui/icons/RemoveRedEye';
import IconKeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Button, TextField, SimpleForm,Toolbar,SaveButton, useNotify, useRedirect, fetchStart, fetchEnd} from 'react-admin';
import RolesInput from './RolesInput';
import dataProvider from '../dataProvider';

const styles = theme => ({
    field: {
        '& span': {
            display: 'inline-block',
            maxWidth: '3s0em'
        }
    }
});


class AddRoleButton extends Component {
    state = { showPanel: false };

    getProvider = () => {
        return dataProvider;
    };

    handleClick = () => {
        this.setState({ showPanel: true });
    };

    handleCloseClick = () => {
        this.setState({ showPanel: false });
    };

    AddRoleToolbar = props => {
        return (
            <Toolbar {...props} >
                <SaveButton
                    label="Add"
                    redirect="show"
                    submitOnEnter={false}
                />
            </Toolbar>
        )};

    AddRoleView = ({ classes, ...props }) => {
        let handleSaveClick = () => {
            const { submit } = this.props;
            submit(this);
        };

        let handleSubmit = (values) => {

            fetchStart();
            const provider = this.getProvider();
            provider.create('roles', { data: values })
                .then(({ data }) => {
                    this.setState({ showDialog: false });
                })
                .catch(error => {
                    this.setState({ showPanel: false});
                })
                .finally(() => {
                    // Dispatch an action letting react-admin know a API call has ended
                    fetchEnd();
                });
        };
        return (
            <SimpleForm
                toolbar={<this.AddRoleToolbar />}
                save={handleSaveClick}
                onSubmit={handleSubmit}
                {...props}>
                <TextField source="id" />
                <TextField source="cn" className={classes.field} />
                <RolesInput
                    reference="roles"
                    source="roles"
                    allowEmpty
                />
            </SimpleForm>
        )};

    mapStateToProps = (state, props) => {
        let data = state.admin.resources[props.resource]
            ? state.admin.resources[props.resource].data[props.id]
            : null;
        delete data.roles;
        return ({
            record: data,
            version: state.admin.ui.viewVersion
        })};



    render() {
        const { showPanel } = this.state;
        const { id } = this.props.record;
        const AddRole = connect(this.mapStateToProps, {})(
            withStyles(styles)(this.AddRoleView)
        );
        return (
            <Fragment>
                <Button onClick={this.handleClick} label="Add Role">
                    <IconImageEye />
                </Button>
                <Drawer
                    anchor="right"
                    open={showPanel}
                    onClose={this.handleCloseClick}
                >
                    <div>
                        <Button label="Close" onClick={this.handleCloseClick}>
                            <IconKeyboardArrowRight />
                        </Button>
                    </div>
                    <AddRole id={id} basePath="/users" resource="users" />
                </Drawer>
            </Fragment>
        );
    }
}

export default connect()(AddRoleButton);


