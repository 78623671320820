import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, useRedirect, fetchStart, fetchEnd, Button } from 'react-admin';


const ResetPasswordButton = ({ record }) => {
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const handleClick = () => {
        console.log(record);
        setLoading(true);
        dispatch(fetchStart()); // start the global loading indicator
        const updatedRecord = { ...record, is_approved: true };
        fetch(`/users/${record.id}/reset_password`, { method: 'PUT', body: updatedRecord })
            .then(() => {
                notify('New password has been sent to you email');
                // redirect('/comments');
            })
            .catch((e) => {
                notify('Error: Something happened', 'warning')
            })
            .finally(() => {
                setLoading(false);
                dispatch(fetchEnd());
            });
    };
    return <Button label="Reset password" onClick={handleClick} disabled={loading} />;
};

export default ResetPasswordButton;
