export default   {
    login: params => Promise.resolve(),
    logout: params => Promise.resolve(),
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            return Promise.reject({ redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    checkAuth: params => {
        console.log(params);
        return Promise.resolve();
    },
    getPermissions: params => Promise.resolve(),
};
