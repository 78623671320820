import React from 'react';
import ReactDOM from 'react-dom';
import {Admin, Resource} from 'react-admin';
import dataProvider from './dataProvider';
import users from './users/';
import Dashboard from './dashboard.js';
import logoutButton from './logoutButton';
import loginPage from './loginPage';
import authProvider from './authProvider';

const App = () => (
    <Admin dataProvider={dataProvider} logoutButton={logoutButton} authProvider={authProvider} loginPage={loginPage}>
        <Resource name="users" {...users}/>
        <Resource name="roles"/>
    </Admin>
);

export default App;
const rootElement = document.getElementById('root');
ReactDOM.render(<App />, rootElement);
