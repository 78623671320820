import {UserCreate} from './UserCreate';
import {UserList} from './UserList';
import {UserShow} from './UserShow';
import {UserEdit} from './UserEdit';

export default {
    create: UserCreate,
    list: UserList,
    show: UserShow,
    edit: UserEdit
};
