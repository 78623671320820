import React from 'react';
import {
    TextField,
    Show,
    SimpleShowLayout,
    Datagrid,
    ArrayField
} from 'react-admin';
import AddRoleButton from './AddRoleButton';
import ResetPasswordButton from './ResetPasswordButton';
import BlockedButton from './BlockedButton';
import DeleteRoleButton from './deleteRoleButton';

export const UserShow = (props) => (
    <Show {...props}>
        <SimpleShowLayout>
            <TextField source="id" title="uid" />
            <TextField source="cn"/>
            <TextField source="mail"/>
            <ArrayField source="roles">
                <Datagrid>
                    <TextField source="name" />
                    <DeleteRoleButton uid={props.id}/>
                </Datagrid>
            </ArrayField>
            <AddRoleButton />
            <ResetPasswordButton/>
            <BlockedButton/>
        </SimpleShowLayout>
    </Show>
);
