import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,required, email
} from 'react-admin';

import RolesInput from './RolesInput'

export const UserCreate = (props) => (
    <Create {...props}>
        <SimpleForm redirect="show">
            <TextInput source="uid" label="uid - i.ivanov"  validate={required()}/>
            <TextInput source="cn" label="Common name - Ivan Ivanov" validate={required()}/>
            <TextInput source="mail" label="email" validate={[required(), email()]} type="email"/>
            <RolesInput
                reference="roles"
                source="roles"
                allowEmpty
            />
        </SimpleForm>
    </Create>
);
