import React from 'react';
import {
    SimpleForm,
    TextInput,required, email, Edit
} from 'react-admin';

export const UserEdit = (props) => (
    <Edit {...props}>
        <SimpleForm redirect="edit">
            <TextInput source="uid" defaultValue={props.uid} validate={required()}/>
            <TextInput source="cn" defaultValue={props.cn} validate={required()}/>
            <TextInput source="mail" defaultValue={props.mail} label="email" validate={[required(), email()]} type="email"/>
        </SimpleForm>
    </Edit>
);