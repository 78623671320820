import React, { Fragment } from 'react';
import { ReferenceInput, SelectArrayInput } from 'react-admin';


const RolesInput = props => (
    <Fragment>

        <ReferenceInput {...props} perPage={500}>
            <SelectArrayInput optionText="name" />
        </ReferenceInput>
    </Fragment>
);

export default RolesInput;
