import React from 'react';
import {
    Datagrid,
    List,
    TextField,ShowButton, Filter, TextInput, EditButton
} from 'react-admin';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
    </Filter>
);

export const UserList = props => (
    <List filters={<UserFilter />} {...props}>
        <Datagrid>
            <TextField source="id" title="uid"/>
            <TextField source="cn"/>
            <TextField source="mail"/>
            <TextField source="ou" title="Status"/>
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);
